import React, { useState, useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/react-hooks';

import { Switch } from 'antd';

import { DISCONNECT_INSTAGRAM } from '../../core/gql/mutations';

const AUTH_URL = 'https://www.instagram.com/oauth/authorize?client_id={app-id}&redirect_uri={redirect-uri}&response_type=code&scope=instagram_business_basic'

const { REACT_APP_INSTAGRAM_APP_ID, REACT_APP_INSTAGRAM_REDIRECT_URL } = window;

const InstagramSwitch = ({ onChange, checked = false }) => {
  const [disconnectInstagram] = useMutation(DISCONNECT_INSTAGRAM);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const instagramAuthUrl = AUTH_URL.replace('{app-id}', REACT_APP_INSTAGRAM_APP_ID).replace(
    '{redirect-uri}',
    REACT_APP_INSTAGRAM_REDIRECT_URL
  );

  const receiveMessage = useCallback(
    ({ data }) => {
      if (data === 'success') {
        onChange(true);
        setError(false);
      } else if (data === 'error') {
        onChange(false);
        setError(true);
      }
      setLoading(false);
    },
    [onChange]
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => window.removeEventListener('message', receiveMessage, false);
  }, [receiveMessage]);

  const handleSwitchChange = (value) => {
    if (value) {
      setLoading(true);

      window.open(instagramAuthUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    } else {
      onChange(value);
      disconnectInstagram();
    }
  };

  return (
    <div>
      <Switch loading={loading} checked={checked} onChange={handleSwitchChange} />
      {error && (
        <span className="color-primary" style={{ fontSize: '0.8em', paddingLeft: 10 }}>
          Fehler aufgetreten
        </span>
      )}
    </div>
  );
};

export default InstagramSwitch;
